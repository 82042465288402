<template>
    <div>
        <b-button variant="primary" v-if="manage" v-b-modal.add-Tts-modal>
            <span class="text-nowrap">Add Tts</span>
        </b-button>
        <b-modal id="add-Tts-modal" hide-footer size="lg" centered title="Add Tts" @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{
            errorMsg }}</b-alert>
                <div class="col-form-label">Select Voice:</div>
                <v-select :close-on-select="true" placeholder="Voice" v-model="voice" :options="options" label="name"
                    input-id="add-voice" class="w-50"></v-select>
                <p class="mb-0 mt-1">Text</p>
                <b-form-textarea v-model="text" id="textarea-rows" placeholder="Hi! I will read any text you type here."
                    rows="8"></b-form-textarea>
                <div class="mt-1" v-if="audioSource">
                    <audio ref="audioPlayer" controls>
                        <source :src="audioSource" type="audio/mp3">
                    </audio>
                </div>
                <div class="modal-footer">
                    <b-button  @click="createTts" :disabled="loading" variant="primary">
                        <b-spinner v-if="loading" small class="mr-1" />
                        Convert
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormGroup,
    BAlert,
    BSpinner,
    BFormTextarea,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
    components: {
        vSelect,
        BButton,
        BFormInput,
        BFormGroup,
        BAlert,
        BSpinner,
        ToastificationContent,
        BFormTextarea,
    },
    data() {
        return {
            text: "",
            voice: { name: "Joanna, Female", value: "Joanna" },
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            options: [
                { name: "Joanna, Female", value: "Joanna" },
                { name: "Danielle, Female", value: "Danielle" },
                { name: "Salli, Female", value: "Salli" },
                { name: "Kimberly, Female", value: "Kimberly" },
                { name: "Kendra, Female", value: "Kendra" },
                { name: "Ivy, Female", value: "Ivy" },
                { name: "Ruth, Female", value: "Ruth" },
                { name: "Gregory, Male", value: "Gregory" },
                { name: "Kevin, Male", value: "Kevin" },
                { name: "Matthew, Male", value: "Matthew" },
                { name: "Justin, Male", value: "Justin" },
                { name: "Joey, Male", value: "Joey" },
            ],
            audioSource: null,
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    methods: {
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.text = "";
            this.errorMsg = "";
        },
        async createTts() {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }
            this.audioSource = null;
            this.loading = true;
            try {
                let ttsData = {
                    text: this.text,
                    voice: this.voice.value,
                };
                const resp = await axios.post(
                    process.env.VUE_APP_API_URL + "/audio",
                    ttsData,
                    {
                        headers: {
                            Authorization: this.token,
                        },
                    }
                );

                if (resp.data.status === 200) {
                    this.audioSource = resp.data.audio_url,
                    this.loading = false;
                }
            } catch (error) {
                if (error.response) {
                    this.errorMsg =
                        error.response.data.error || "An error occurred";
                } else if (error.request) {
                    this.errorMsg =
                        "No response received from server. Please check your network connection.";
                } else {
                    this.errorMsg =
                        "Error in setting up the request: " + error.message;
                }
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.text.length) {
                this.errorMsg = "Text required.";
                return;
            }

            return "ok";
        },
    },
    props: ["manage", "token"],
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.dropdown button {
    padding: 0 !important;
}

.dropdown-menu {
    min-width: 8rem;
    margin-top: -17px !important;
}

.dropdown a.dropdown-item {
    padding: 0px 8px;
}

.vs__open-indicator {
    fill: #9d9d9d;
}
</style>
